<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت برند</b-button>
    <b-card title="برندها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">اسم</label>
                  <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="1">فعال</b-form-select-option>
                    <b-form-select-option value="0">غیرفعال</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <div class="table-responsive">
        <table v-if="items.data" class="table b-table table-striped table-hover">
          <thead>
            <tr>
              <th>شناسه</th>
              <th>نام</th>
              <th>نام انگلیسی</th>
              <th>وضعیت</th>
              <th>تاریخ ثبت</th>
              <th>ویرایش</th>
              <th>حذف</th>
            </tr>
          </thead>
          <draggable v-if="items.data != ''" v-model="items.data" tag="tbody" @change="sort">
              <tr v-for="(item,index) in items.data" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.english_name }}</td>
                <td>
                  <span class="badge badge-success" v-if="item.status == 1">فعال</span>
                  <span class="badge badge-light" v-else>غیرفعال</span>
                </td>
                <td>{{ item.created_at | persianDate }}</td>
                <td>
                  <button class="btn btn-primary" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
                <td>
                  <button class="btn btn-danger" @click="deleteItem(item.id)">
                    <i class="fa fa-close"></i>
                  </button>
                </td>
              </tr>
            </draggable>
        </table>
      </div>
      <div class="alert alert-danger text-right" v-if="items.data && items.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <b-modal id="new-modal" title="ثبت برند" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <b-form-group>
            <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="name" name="name" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="english_name"> عنوان لاتین <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="english_name" name="english_name" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="logo"> لوگو <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-file id="logo" name="logo" data-required :disabled="disabled"></b-form-file>
          </b-form-group>

          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>

          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="special" class="custom-switch-input" v-model="selected_special"
                     :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">منتخب</span>
            </label>
          </div>

          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش برند" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <b-form-group>
            <label for="edit-name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="edit-name" name="name" v-model="edit.name" data-required :disabled="disabled">
            </b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="edit-english_name"> عنوان لاتین <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="edit-english_name" name="english_name" v-model="edit.english_name" data-required
              :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="edit-logo"> لوگو <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-file id="edit-logo" name="logo" :disabled="disabled"></b-form-file>
            <img v-if="edit.logo_url" :src="edit.logo_url" />
          </b-form-group>

          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="special" class="custom-switch-input" v-model="edit.special"
                     :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">منتخب</span>
            </label>
          </div>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="PUT" name="_method">
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/brands',
        title: 'برندها',
        selected_status: '1',
        selected_filter_status: '',
        selected_special: '0'
      }
    },
    methods: {
      sort() {
        let ids = this.items.data.map(brand => brand.id);
        this.$axios.post(this.$root.baseUrl + '/api/admin/brands/sort', {ids})
            .then(response => {
              window.swal({
                title: 'با موفقیت مرتب سازی شد'
              })
            }).catch(() => {
          window.swal({
            title: 'ارور'
          })
        })
      },
    },
    created() {
      this.loadItems()
      this.searchParams = {
        name: '',
        status: '',
      }
    }
  }
</script>
